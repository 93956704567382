import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getProfiles } from '../../../store/profile-select/actions';
import { updateProfile } from "../../auth/authActions";

const ChangeUserProfile = () => {

  const dispatch = useDispatch();
  const profile = useSelector(({ authentication }) => authentication.profile);
  const websiteProfiles = useSelector(({ profileSelect }) => profileSelect.profileList);
  
  const isAuthenticated = useSelector(
    state =>
      state.authentication.isAuthenticated &&
      state.authentication.accessToken &&
      state.authentication.profile,
  );

  const handleChange = async (profileId) => {
    await dispatch(updateProfile(profileId));
    window.location = window.location.origin;
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getProfiles())
    }
  }, [dispatch, isAuthenticated]);

  return (
    <div>
      { (profile.canSwitchProfile && websiteProfiles.length) ? (
          <FormControl  fullWidth >
            <Select
              labelId="select-label"
              id="simple-select"
              sx={{ minWidth: 120, height: 40 }}
              className="select-role"
              value={profile.profile.name}
              onChange={(event, child) => handleChange(child.props.id)}
            >
              {websiteProfiles?.map(p => (
                <MenuItem id={p.id} key={p.id} value={p.name}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

      ) : (
        <></>
      )}
    </div>
  );
};

export default ChangeUserProfile;
